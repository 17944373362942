import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PersistDataService} from '../shared/services/persist-data.service';
import {Lead} from '../shared/models/lead';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AppStateService} from '../shared/services/app-state.service';
import {GeoLocationService} from '../shared/services/geo-location.service';

@Component({
  selector: 'app-self-insurance-flow',
  templateUrl: './self-insurance-flow.component.html'
})
export class SelfInsuranceFlowComponent implements OnInit {
  lead = new Lead();
  buttonTitle = 'See Plans';
  zip_code: string;
  stored_lead = new Lead();
  validZipCode = false;
  loading = false;
  mainTitle = 'Find Healthcare that Works for You.';
  valuePoints = [
    'Choose from 100+ plans with the same pricing as brokers',
    'Get a fully customized instant quote',
    'Talk to licensed brokers for all your questions',
    'Enroll by phone or online & receive the best prices'
  ];
  phoneNumber = this._appState.phoneNumber;
  phoneNumberLink = this._appState.phoneNumberLink;
  @ViewChild('displayZipcode') nameField: ElementRef;
  zip_focus = true;

  constructor(
    private _route: ActivatedRoute,
    private _appState: AppStateService,
    private _router: Router,
    private _geoLocation: GeoLocationService,
    private _persistData: PersistDataService
  ) {
    this._appState.lead.leadData.phoneCell = 'NA';
    this._appState.lead.leadData.phoneCell = 'NA';
    this.stored_lead = this._appState.lead;
    this._appState.validZipcode.subscribe(
      (data) => {
        this.validZipCode = data;
      }
    );
    this._appState.loading.subscribe(
      (loading) => this.loading = loading
    );
    this._appState.zipCodeTitle.subscribe(
      (title) => this.buttonTitle = title
    );
  }

  ngOnInit() {
    this.zip_code = this._route.snapshot.queryParamMap.get('zip_code') || '';
    this.nameField.nativeElement.focus();
  }

  goToNextStep() {
    if (this.validZipCode) {
      this.stored_lead.geoLocation.zipcode = this.zip_code;
      this._appState.lead = this.stored_lead;
      this._persistData.saveDataIntoStorage(this.stored_lead);
      this._router.navigate(['/step-1'], {queryParams: {zip_code: this.zip_code}, queryParamsHandling: 'merge'}).then(result => {
        window.open('fas.html', '_blank');
      });
    }
  }

  nextStep() {
    // this.zip_focus = false;
    if (this.zip_code.length > 4 && this.validZipCode) {
      window.open('step-1?zip_code=' + this.zip_code, '_blank');
      this._router.navigate(['/'], {queryParams: {zipcode: this._appState.lead.geoLocation.zipcode}})
        .then(result => {
          window.location.href = '/fas.html?zipcode=' + this._appState.lead.geoLocation.zipcode + '&state=' + this._appState.lead.geoLocation.state + '&city=' + this._appState.lead.geoLocation.city;
        });
    }
  }

  validateZipCode() {
    if (this.zip_code.length > 4) {
      this._geoLocation.getZipcode(this.zip_code);
    } else {
      this._appState.validZipcode.next(false);
    }
  }

  nextStepEnter() {
    // this.zip_focus = false;
    if (this.zip_code.length > 4 && this.validZipCode) {
      window.open('step-1?zip_code=' + this.zip_code, '_blank');
      this._router.navigate(['/'], {queryParams: {zipcode: this._appState.lead.geoLocation.zipcode}})
        .then(result => {
          window.location.href = '/fas.html?zipcode=' + this._appState.lead.geoLocation.zipcode + '&state=' + this._appState.lead.geoLocation.state + '&city=' + this._appState.lead.geoLocation.city;
        });
    }
  }

  zipFocus() {
    // this.zip_focus = true;
  }
}
