import {AfterViewInit, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppStateService} from '../../shared/services/app-state.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html'
})
export class ThankYouComponent implements OnInit{
  city: string;
  state: string;
  zipcode: string;
  mediaalpha;

  constructor(private _appState: AppStateService, private sanitizer: DomSanitizer) {
    this.city = this._appState.lead.geoLocation.city;
    this.state = this._appState.lead.geoLocation.state;
    this.zipcode = this._appState.lead.geoLocation.zipcode;
    this.mediaalpha = sanitizer.bypassSecurityTrustHtml('<div><script type="text/javascript"> alert(\'h\');</script></div>');
  }

  ngOnInit() {}
}
