import {Component, Input, OnInit} from '@angular/core';
import {Member} from '../../models/member';
import {MatDatepickerInputEvent} from '@angular/material';
import {AppStateService} from '../../services/app-state.service';

// @ts-ignore
@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html'
})
export class FamilyMemberComponent implements OnInit {
  @Input() relation: string;
  @Input() iteration: number;
  member = new Member();

  constructor(private _appState: AppStateService) {
    this.member.gender = 'male';
    this.member.tobaccoUse = 'no';
  }

  ngOnInit() {
    this._appState['familyMember' + this.iteration].gender = this.member.gender;
    this._appState['familyMember' + this.iteration].tobaccoUse = this.member.tobaccoUse;
    this._appState['familyMember' + this.iteration].relationship = this.relation;
  }

  setGender(gender) {
    this.member.gender = gender;
    this._appState['familyMember' + this.iteration].gender = this.member.gender;
  }

  setTobaccoUse(tobacco) {
    this.member.tobaccoUse = tobacco;
    this._appState['familyMember' + this.iteration].tobaccoUse = this.member.tobaccoUse;
  }


}
