import {Component, OnInit} from '@angular/core';
import {PersistDataService} from '../../../shared/services/persist-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppStateService} from '../../../shared/services/app-state.service';
import {Lead} from '../../../shared/models/lead';
import {Member} from '../../../shared/models/member';
import {MatDatepickerInputEvent} from '@angular/material';
import has = Reflect.has;

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html'
})
export class Step1Component implements OnInit {
  leadInformation = new Lead();
  houseHoldIncome = '$30,001 - $40,000';
  houseHold = 1;
  reasonForShopping = 'None of These Apply';
  houseHoldIncomeValidation = false;
  houseHoldValidation = false;
  reasonForShoppingValidation = false;
  city = '';
  state = '';
  nextStepDisabled = true;
  phoneNumber: string;
  selfEmployee = true;
  houseHoldMembers = [
    {'id': 1, 'name': 1},
    {'id': 2, 'name': 2},
    {'id': 3, 'name': 3},
    {'id': 4, 'name': 4},
    {'id': 5, 'name': 5},
    {'id': 6, 'name': 6},
  ];
  houseHoldIncomeRanges = [
    {'id': '$0 - $14,999'},
    {'id': '$15,000 - $30,000'},
    {'id': '$30,001 - $40,000'},
    {'id': '$40,001 and Over'},
  ];
  reasonForShoppingOptions = [
    {'id': 'None of These Apply'},
    {'id': 'Lost Coverage'},
    {'id': 'Got Married or Divorced'},
    {'id': 'Had A Baby or Adopted One'},
    {'id': 'Lost a Parent or Spouse'},
    {'id': 'Moved to a New State'},
    {'id': 'Lost Your Job'},
    {'id': 'Started a New Job'},
  ];

  constructor(
    private _appState: AppStateService,
    private _router: Router,
    private _persistData: PersistDataService
  ) {
    this.leadInformation = this._appState.lead;
  }

  ngOnInit() {
    this.city = this.leadInformation.geoLocation.city;
    this.state = this.leadInformation.geoLocation.state;
    this.phoneNumber = this._appState.phoneNumber;
  }

  saveForm() {
    this.houseHoldIncomeValidation = !this.houseHoldIncome || Number(this.houseHoldIncome) <= 0;
    // this.houseHoldValidation = this.houseHold === '0';
    this.reasonForShoppingValidation = !this.reasonForShopping || Number(this.reasonForShopping) <= 0;

    this.leadInformation.leadData.houseHold = this.houseHold.toString();
    this.leadInformation.leadData.houseHoldIncome = this.houseHoldIncome;
    this.leadInformation.leadData.qualifyingLifeEvent = this.reasonForShopping;
    this.leadInformation.leadData.selfEmployee = this.selfEmployee;
    this.leadInformation.userContext.query_string = JSON.parse(JSON.stringify(this.leadInformation.leadData));
    this._appState.lead = this.leadInformation;
    if (!this.houseHoldIncomeValidation && !this.houseHoldValidation && !this.reasonForShoppingValidation) {
      this._persistData.saveDataIntoStorage(this.leadInformation);
      this._router.navigate(['/step-3'],
        {queryParams: JSON.parse(JSON.stringify(this.leadInformation.userContext.query_string)), queryParamsHandling: 'merge'});
    }
  }

  // householdChange(event) {
  //   this.houseHoldValidation = this.houseHold === '0';
  // }

  validateHouseHoldIncome() {
    this.houseHoldIncomeValidation = !(this.houseHoldIncome || Number(this.houseHoldIncome) > 0);
  }

  validateReasonForShopping(event) {
    this.reasonForShoppingValidation = !(this.reasonForShopping || Number(this.reasonForShopping) > 0);
  }

  setSelfEmployee(status) {
    this.selfEmployee = status;
  }
}
