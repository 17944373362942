import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SelfInsuranceFlowComponent} from './self-insurance-flow/self-insurance-flow.component';
import {Step1Component} from './self-insurance-flow/flowA/step1/step1.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {Step2Component} from './self-insurance-flow/flowA/step2/step2.component';
import {FamilyCompositionComponent} from './self-insurance-flow/flowA/family-composition/family-composition.component';
import {CoverageTimeComponent} from './self-insurance-flow/flowA/coverage-time/coverage-time.component';
import {ThankYouComponent} from './self-insurance-flow/thank-you/thank-you.component';
import {PrivacyComponent} from './self-insurance-flow/privacy/privacy.component';
import {TermsComponent} from './terms/terms.component';

const routes: Routes = [
  { path: '', component: SelfInsuranceFlowComponent },
  { path: 'step-1', component: CoverageTimeComponent },
  { path: 'step-2', component: Step1Component },
  { path: 'step-3', component: FamilyCompositionComponent },
  { path: 'step-4', component: Step2Component },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms-use', component: TermsComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
