import {Component, OnInit} from '@angular/core';
import {Member} from '../../../shared/models/member';
import {MatDatepickerInputEvent} from '@angular/material';
import {Lead} from '../../../shared/models/lead';
import {AppStateService} from '../../../shared/services/app-state.service';
import {Router} from '@angular/router';
import {PersistDataService} from '../../../shared/services/persist-data.service';

@Component({
  selector: 'app-family-composition',
  templateUrl: './family-composition.component.html'
})
export class FamilyCompositionComponent implements OnInit {
  leadInformation = new Lead();
  nextStepDisabled = true;
  gender = 'male';
  tobaccoUse = 'no';
  dob: string;
  showSpouse = false;
  showChildren1 = false;
  showChildren2 = false;
  showChildren3 = false;
  showChildren4 = false;
  childrenSelected = 1;
  spouse = new Member();
  children1 = new Member();
  children2 = new Member();
  children3 = new Member();
  children4 = new Member();
  familyMembers = new Array<Member>();
  dobValidation = false;
  houseHoldIncomeStyle = 'houseHoldIncomeStyleInvalid';
  applicantCalendar = new Date(Date.now());
  childrenCalendar = new Date(Date.now());
  activeSpouse = false;
  showSpouseOption: boolean;
  showSpouseTobacco: boolean;

  constructor(
    private _appState: AppStateService,
    private _router: Router,
    private _persistData: PersistDataService
  ) {
    this.leadInformation = this._appState.lead;
  }

  ngOnInit() {
    this.applicantCalendar.setDate(this.applicantCalendar.getDate() - 6570);
    this.childrenCalendar.setDate(this.childrenCalendar.getDate() - 9490);
  }

  showChildren() {
    if (this.childrenSelected <= 4) {
      this['showChildren' + this.childrenSelected.toString()] = true;
      this['children' + this.childrenSelected.toString()].gender = 'male';
      this['children' + this.childrenSelected.toString()].tobaccoUse = 'no';
      this['children' + this.childrenSelected.toString()].relationship = 'children' + this.childrenSelected.toString();
      if (this.childrenSelected < 5) {
        this.childrenSelected++;
      }
    }
  }

  hideChildren() {
    if (this.childrenSelected > 0) {
      if (this.childrenSelected > 1) {
        this.childrenSelected--;
      }
      this['showChildren' + this.childrenSelected.toString()] = false;
    }
  }

  setGender(gender, member) {
    switch (member) {
      case 'spouse': {
        this.spouse.gender = gender;
        break;
      }
      case 'children1': {
        this.children1.gender = gender;
        break;
      }
      case 'children2': {
        this.children2.gender = gender;
        break;
      }
      case 'children3': {
        this.children3.gender = gender;
        break;
      }
      case 'children4': {
        this.children4.gender = gender;
        break;
      }
      case 'applicant': {
        this.gender = gender;
        break;
      }
    }
  }

  setTobaccoUse(tobacco, member) {
    switch (member) {
      case 'spouse': {
        this.spouse.tobaccoUse = tobacco;
        break;
      }
      case 'children1': {
        this.children1.tobaccoUse = tobacco;
        break;
      }
      case 'children2': {
        this.children2.tobaccoUse = tobacco;
        break;
      }
      case 'children3': {
        this.children3.tobaccoUse = tobacco;
        break;
      }
      case 'children4': {
        this.children4.tobaccoUse = tobacco;
        break;
      }
      case 'applicant': {
        this.tobaccoUse = tobacco;
        break;
      }
    }
  }

  changeDob(event: MatDatepickerInputEvent<Date>, member) {
    switch (member) {
      case 'spouse': {
        this.spouse.dob = event.value.toDateString();
        break;
      }
      case 'children1': {
        this.children1.dob = event.value.toDateString();
        break;
      }
      case 'children2': {
        this.children2.dob = event.value.toDateString();
        break;
      }
      case 'children3': {
        this.children3.dob = event.value.toDateString();
        break;
      }
      case 'children4': {
        this.children4.dob = event.value.toDateString();
        break;
      }
    }
  }

  validateChangeDate(event) {
    this.dobValidation = !this.dob;
    this.checkValidation();
  }

  checkValidation() {
    this.nextStepDisabled = !this.dob;
  }

  saveForm() {
    const hasSpouse = this.familyMembers.filter(i => i.relationship === 'spouse');
    if (hasSpouse.length < 1) {
      if (Object.getOwnPropertyNames(this.spouse).length !== 0) {
        this.familyMembers.push(this.spouse);
      }
    }
    const hasChildren1 = this.familyMembers.filter(i => i.relationship === 'children1');
    if (hasChildren1.length < 1) {
      if (Object.getOwnPropertyNames(this.children1).length !== 0) {
        this.familyMembers.push(this.children1);
      }
    }
    const hasChildren2 = this.familyMembers.filter(i => i.relationship === 'children2');
    if (hasChildren2.length < 1) {
      if (Object.getOwnPropertyNames(this.children2).length !== 0) {
        this.familyMembers.push(this.children2);
      }
    }
    const hasChildren3 = this.familyMembers.filter(i => i.relationship === 'children3');
    if (hasChildren3.length < 1) {
      if (Object.getOwnPropertyNames(this.children3).length !== 0) {
        this.familyMembers.push(this.children3);
      }
    }
    const hasChildren4 = this.familyMembers.filter(i => i.relationship === 'children4');
    if (hasChildren4.length < 1) {
      if (Object.getOwnPropertyNames(this.children4).length !== 0) {
        this.familyMembers.push(this.children4);
      }
    }
    this.dobValidation = !this.dob;
    this.leadInformation.leadData.gender = this.gender;
    this.leadInformation.leadData.tobaccoUse = this.tobaccoUse;
    this.leadInformation.leadData.dob = this.dob;
    this.leadInformation.leadData.familyMembers = this.familyMembers;
    this.leadInformation.userContext.query_string = JSON.parse(JSON.stringify(this.leadInformation.leadData));
    this._appState.lead = this.leadInformation;
    if (!this.dobValidation) {
      this._persistData.saveDataIntoStorage(this.leadInformation);
      this._router.navigate(['/step-4'],
        {queryParams: JSON.parse(JSON.stringify(this.leadInformation.userContext.query_string)), queryParamsHandling: 'merge'});
    }
  }

  activateSpouse() {
    this.showSpouse = true;
    this.activeSpouse = true;
    if (this.gender === 'male') {
      this.showSpouseOption = false;
      this.setGender('female', 'spouse');
    } else if (this.gender === 'female') {
      this.showSpouseOption = true;
      this.setGender('male', 'spouse');
    }
    this.showSpouseTobacco = true;
    this.setTobaccoUse('no', 'spouse');
  }

  hideSpouse() {
    this.showSpouse = false;
    this.activeSpouse = false;
    this.showSpouseOption = null;
    this.showSpouseTobacco = null;
  }

}
