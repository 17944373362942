import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppStateService} from './app-state.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  endpoint = environment.leadEndPoint;

  constructor(private _http: HttpClient, private _appState: AppStateService, private _router: Router) {
  }

  public getZipcode(zipcode: string) {
    try {
      this._appState.loading.next(true);
      this.getGeoInformation(zipcode).subscribe(
        (data) => {
          this._appState.zipCodeTitle.next('Validating Zip Code');
          if (data) {
            this._appState.lead.geoLocation.city = data['city'];
            this._appState.lead.geoLocation.zipcode = data['zip_code'];
            this._appState.lead.geoLocation.state = data['state'];
            this._appState.validZipcode.next(true);
            this._appState.loading.next(false);
            this._appState.zipCodeTitle.next('Get your insurance quote');
          } else {
            this._appState.validZipcode.next(false);
            this._appState.loading.next(false);
            this._appState.zipCodeTitle.next('Zip Code not valid');
          }
        },
        (error) => {
          this._appState.validZipcode.next(false);
          this._appState.loading.next(false);
          this._appState.zipCodeTitle.next('There is a problem');
        }, () => {}
      );
    } catch (e) {
      console.log(e);
    }
  }

  public getZipcodeLoad(zipcode: string) {
    try {
      this.getGeoInformation(zipcode).subscribe(
        (data) => {
          if (data) {
            this._appState.lead.geoLocation.city = data['city'];
            this._appState.lead.geoLocation.zipcode = data['zip_code'];
            this._appState.lead.geoLocation.state = data['state'];
            this._appState.validZipcode.next(true);
            this._appState.loading.next(false);
            this._appState.zipCodeTitle.next('Get your insurance quote');
          } else {
            this._appState.validZipcode.next(false);
            this._appState.loading.next(false);
            this._appState.zipCodeTitle.next('Zip Code not valid');
          }
        },
        (error) => {
          this._appState.zipCodeTitle.next('There is a problem');
        }, () => {
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  private getGeoInformation(zipcode: string) {
    try {
      return this._http.get(this.endpoint + 'api/Get_GeoLocation/?zipcode=' + zipcode);
    } catch (e) {
      console.log(e);
    }
  }
}
