import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Lead} from '../models/lead';
import {Member} from '../models/member';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  clientIp = new Subject<string>();
  lead = new Lead;
  sessionId = new Subject<string>();
  flattenObject: string;
  validZipcode = new Subject<boolean>();
  loading = new Subject<boolean>();
  loadingText = new Subject<string>();
  phoneNumber = '855-COVER-99';
  phoneNumberLink = '+18552683799';
  zipCodeTitle = new Subject<string>();
  constructor() { }

}
