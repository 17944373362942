import {Member} from './member';

class UserContext {
  userAgent: string;
  ip_address: string;
  query_string: string;
  session_id: string;
}

class LeadContext {
  vertical: string;
  experiment_id: string;
  jornayaToken: string;
  campaign: string;
  token: string;
  date: string;
  landingPage: string;
  tcpaLanguage: string;
}

class ClickContext {
  subId: string;
  channel: string;
  src: string;
  ad_id: string;
  ad_position: string;
  referrer: string;
  utm_src: string;
  utm_medium: string;
  utm_term: string;
  utm_content: string;
  utm_campaign: string;
  click_id: string;
  affiliate_id: string;
  affiliate_source_id: string;
  affiliate_sub_id: string;
  gclid: string;
  prepulated: string;
}

class TrackingParam {
  name: string;
}

class LeadData {
  firstName: string;
  lastName: string;
  email: string;
  phoneHome: string;
  phoneCell: string;
  phoneWork: string;
  phoneExt: string;
  dob: string;
  tobaccoUse: string;
  maritalStatus: boolean;
  houseHold: string;
  houseHoldIncome: string;
  gender: string;
  qualifyingLifeEvent: string;
  familyMembers = new Array<Member>();
  selfEmployee: boolean;
  coverage: string;
}

class GeoLocation {
  zipcode: string;
  county: string;
  city: string;
  state: string;
  country = 'USA';
  address: string;
}

class CampaignDetails {
  campaignId = '5cb7b6809b66d';
  campaignKey = 'KF2wbypB6PJHGcLqvhMV';
  campaignTest = '0';
}

export class Lead {
  userContext: UserContext = new UserContext();
  leadContext: LeadContext = new LeadContext();
  clickContext: ClickContext = new ClickContext();
  leadData: LeadData = new LeadData();
  geoLocation: GeoLocation = new GeoLocation();
  campaginDetails: CampaignDetails = new CampaignDetails();
}

