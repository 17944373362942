import {Component, OnInit} from '@angular/core';
import {Lead} from '../../../shared/models/lead';
import {AppStateService} from '../../../shared/services/app-state.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PersistDataService} from '../../../shared/services/persist-data.service';
import {GeoLocationService} from '../../../shared/services/geo-location.service';

@Component({
  selector: 'app-coverage-time',
  templateUrl: './coverage-time.component.html'
})
export class CoverageTimeComponent implements OnInit {
  lead = new Lead();

  constructor(
    private _appState: AppStateService,
    private _router: Router,
    private _persistData: PersistDataService,
    private _geo: GeoLocationService,
    private _route: ActivatedRoute
  ) {
    this.lead = this._appState.lead;
    this.lead.geoLocation.zipcode = this._route.snapshot.queryParamMap.get('zip_code') || '33172';
    console.log(this.lead.geoLocation.zipcode);
  }

  ngOnInit() {
    this._geo.getZipcodeLoad(this.lead.geoLocation.zipcode);
  }

  setCoverage(time) {
    this._appState.lead.leadData.coverage = time;
    this._persistData.saveDataIntoStorage(this.lead);
    // @ts-ignore
    this._router.navigate(['/step-2'], {queryParams: JSON.parse(JSON.stringify(this.lead.userContext.query_string)), queryParamsHandling: 'merge'});
  }
}
