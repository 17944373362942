// libraries
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// internal modules
import {AppRoutingModule} from './app-routing.module';
import {AppMaterialModule} from './app-material/app-material.module';

// components
import {AppComponent} from './app.component';
import {SelfInsuranceFlowComponent} from './self-insurance-flow/self-insurance-flow.component';

// services
import {AppStateService} from './shared/services/app-state.service';
import {PersistDataService} from './shared/services/persist-data.service';
import {HeaderComponent} from './shared/components/header/header.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {Step1Component} from './self-insurance-flow/flowA/step1/step1.component';
import {Step2Component} from './self-insurance-flow/flowA/step2/step2.component';
import {Step3Component} from './self-insurance-flow/flowA/step3/step3.component';
import {SessionService} from './shared/services/session.service';
import {NgxMaskModule} from 'ngx-mask';
import { NotFoundComponent } from './not-found/not-found.component';
import { FamilyMemberComponent } from './shared/components/family-member/family-member.component';
import {GeoLocationService} from './shared/services/geo-location.service';
import { CoverageTimeComponent } from './self-insurance-flow/flowA/coverage-time/coverage-time.component';
import { FamilyCompositionComponent } from './self-insurance-flow/flowA/family-composition/family-composition.component';
import { LivePersonComponent } from './shared/components/live-person/live-person.component';
import {CookieService} from 'ngx-cookie-service';
import { ThankYouComponent } from './self-insurance-flow/thank-you/thank-you.component';
import { PrivacyComponent } from './self-insurance-flow/privacy/privacy.component';
import { SafePipe } from './safe.pipe';
import { TermsComponent } from './terms/terms.component';



@NgModule({
  declarations: [
    AppComponent,
    SelfInsuranceFlowComponent,
    HeaderComponent,
    FooterComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    NotFoundComponent,
    FamilyMemberComponent,
    CoverageTimeComponent,
    FamilyCompositionComponent,
    LivePersonComponent,
    ThankYouComponent,
    PrivacyComponent,
    SafePipe,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    BrowserAnimationsModule,
    AppMaterialModule
  ],
  providers: [AppStateService, PersistDataService, SessionService, GeoLocationService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
