import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../../services/app-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  phoneNumber: string;
  loading = false;
  phoneNumberLink: string;

  constructor(private _app: AppStateService) {
    this.phoneNumber = _app.phoneNumber;
    this.phoneNumberLink = _app.phoneNumberLink;
    this._app.loading.subscribe(
      (loading) => this.loading = loading
    );
  }

  ngOnInit() {}

}
