import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Lead} from '../models/lead';
import {LocalStorageService} from 'ngx-webstorage';
import {AppStateService} from './app-state.service';
import {ActivatedRoute} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class PersistDataService {
  endpoint = environment.leadEndPoint;
  saveDataUrl = environment.leadEndPoint + 'api/GetLeadToQueue';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private _http: HttpClient, private _localStorage: LocalStorageService, private _appState: AppStateService, private _route: ActivatedRoute) {
    this.saveDataIntoStorage(this._appState.lead);
  }

  saveDataIntoStorage(lead: Lead) {
    try {
      this._localStorage.store('lead', lead);
    } catch (e) {
      console.log(e);
    }
  }

  saveDataIntoService() {
    this._appState.lead.leadContext.date = (new Date(Date.now())).toDateString();
    this.createPostToService().subscribe(
      (data) => {
      },
      (error) => console.log(error),
      () => {
        this._appState.loading.next(false);
        const vertical = this._route.snapshot.queryParamMap.get('v');
        if (vertical === 'health') {
          window.open('thankyou.html?zipcode=' + this._appState.lead.geoLocation.zipcode + '&applicant=' + this._appState.lead.leadData.firstName, '_self');
        } else {
          window.open('thankyou-medicare.html?zipcode=' + this._appState.lead.geoLocation.zipcode + '&applicant=' + this._appState.lead.leadData.firstName, '_self');
        }

      },
    );
  }

  createPostToService() {
    try {
      this._appState.lead.leadContext.landingPage = 'www.insuranceplans.org';
      this._appState.lead.leadContext.tcpaLanguage = 'By clicking "SUBMIT" you are providing your prior written consent to share your ' +
        'information with up to five partners from Trusted Consumer and Gateway parties calling on behalf of Trusted Consumer, ' +
        'Premier Partners or authorized third parties on their behalf to contact you (including through automated means; ' +
        'autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, ' +
        'even in your telephone number is currently listed on any state, federal or corporate Do Not Call list. ' +
        'You agree t our <a routerLink="/terms-use" target="_blank">Terms of Use</a> ' +
        'and <a routerLink="/privacy" target="_blank">Privacy</a> Policy. This consent is not a condition of a purchase.';
      console.log(this._appState.lead);
      const saveApplication = this._http.post(
        this.saveDataUrl,
        JSON.stringify(this._appState.lead),
        this.httpOptions
      );
      return saveApplication;
    } catch (e) {
      console.log(e);
    }
  }
}
