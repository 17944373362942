import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppStateService} from '../../../shared/services/app-state.service';
import {Lead} from '../../../shared/models/lead';
import {PersistDataService} from '../../../shared/services/persist-data.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html'
})
export class Step2Component implements OnInit {
  lead = new Lead();
  firstName: string;
  validateFirstName = false;
  lastName: string;
  validateLastName = false;
  address: string;
  validateAddress = false;
  email: string;
  validateEmail = false;
  phoneNumber = '';
  validatePhone = false;
  nextStepDisabled = true;
  isMobile = true;
  jornaya: string;

  constructor(
    private _router: Router,
    private _appState: AppStateService,
    private _persistData: PersistDataService,
    private _cookieService: CookieService
  ) {
    this.lead = this._appState.lead;
  }

  ngOnInit() {
    this.jornaya = this._cookieService.get('leadid_token-B04E3B3A-5ADD-35D0-8BCD-15234DDCEBBC-6D014860-3F26-0CA7-BC3A-BB581854E636');
  }

  saveLead() {
    // firstname lastname validation
    const regexp = new RegExp(/[a-zA-Z]{2,5}/);
    this.validateFirstName = !this.firstName || this.firstName.length < 2 || !regexp.test(this.firstName);
    this.validateLastName = !this.lastName || this.lastName.length < 2 || !regexp.test(this.lastName);
    // address validation
    const addressRegEx = new RegExp(/^(\d{3,})\s?(\w{0,5})\s([a-zA-Z]{2,30})\s([a-zA-Z]{2,15})\.?\s?(\w{0,5})$/);
    this.validateAddress = !this.address || !addressRegEx.test(this.address);
    // email validation
    const emailReg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.validateEmail = !this.email || !emailReg.test(this.email);
    // phone validation
    this.validatePhone = !this.phoneNumber || this.phoneNumber.length < 10;

    this.lead.leadData.firstName = this.firstName;
    this.lead.leadData.lastName = this.lastName;
    this.lead.leadData.email = this.email;
    this.lead.geoLocation.address = this.address;
    if (!this.isMobile) {
      this.lead.leadData.phoneHome = this.phoneNumber;
    } else {
      this.lead.leadData.phoneCell = this.phoneNumber;
    }
    this.lead.userContext.query_string = JSON.parse(JSON.stringify(this.lead.leadData));
    this.lead.leadContext.jornayaToken = this.jornaya;
    if (!this.validateLastName && !this.validateFirstName && !this.validateAddress && !this.validateEmail && !this.validatePhone) {
      this._persistData.saveDataIntoStorage(this.lead);
      this._persistData.saveDataIntoService();
      this._appState.loading.next(true);
      this._appState.loadingText.next('Looking for insurance providers near you');
      // @ts-ignore
      // this._router.navigate(['/thank-you'], {queryParams: this.lead.userContext.query_string, queryParamsHandling: 'merge'});
    }
  }

  validFirstName() {
    const regexp = new RegExp(/[a-zA-Z]{2,5}/);
    this.validateFirstName = !this.firstName || this.firstName.length < 2 || !regexp.test(this.firstName);
    this.validateButton();
  }

  validLastName() {
    const regexp = new RegExp(/[a-zA-Z]{2,5}/);
    this.validateLastName = !this.lastName || this.lastName.length < 2 || !regexp.test(this.lastName);
    this.validateButton();
  }

  validAddress() {
    const addressRegEx = new RegExp(/^(\d{3,})\s?(\w{0,5})\s([a-zA-Z]{2,30})\s([a-zA-Z]{2,15})\.?\s?(\w{0,5})$/);
    this.validateAddress = !this.address || !addressRegEx.test(this.address);
    this.validateButton();
  }

  validEmail() {
    const emailReg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.validateEmail = !this.email || !emailReg.test(this.email);
    this.validateButton();
  }

  validPhone() {
    // phone validation
    this.validatePhone = !this.phoneNumber || this.phoneNumber.length <= 9;
    this.validateButton();
  }

  validateButton() {
    this.nextStepDisabled = !(!this.validateLastName && !this.validateFirstName
      && !this.validateAddress && !this.validateEmail && !this.validatePhone);
  }

  phoneType(event) {
    this.isMobile = event;
  }
}
