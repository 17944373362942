import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../../services/app-state.service';

@Component({
  selector: 'app-live-person',
  templateUrl: './live-person.component.html'
})
export class LivePersonComponent implements OnInit {
  phoneNumber: string;
  phoneNumberLink: string;

  constructor(private _appState: AppStateService) {
    this.phoneNumber = this._appState.phoneNumber;
    this.phoneNumberLink = this._appState.phoneNumberLink;
  }

  ngOnInit() {
  }

}
